.clip {
  transition: all 1s ease;
  clip-path: inset(0 100% 0 0);
}

.reverse {
  clip-path: inset(0 0 0 100%);
}

.reveal {
  clip-path: inset(0);
}

.long {
  transition: all 2s ease;
}
