
@keyframes hidden {
  from {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 24px;
  }
  to {
    opacity: 0;
    margin-top: -48px;
    margin-bottom: 0;
  }
}

@keyframes reveal {
  from {
    opacity: 0;
    margin-top: 48px;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.reveal  > * {
  animation: reveal 0.8s ease-in-out forwards, hidden 1s ease-in-out 2s forwards;
}
